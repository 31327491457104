
@import 'custom';

body {
  background: $bg-body;
  color: $color-body;
}

html, body {
  font-family: $font-stack-primary;
  font-size: $font-size;

  height: 100%;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;

  font-family: $font-stack-secondary;
  font-weight: 400;
  letter-spacing: -0.026em;
}

p {
  line-height: $p-line-height;
}

a {
  color: #000000;
}

a:hover,
a:active {
  color: #000000;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

del {
    font-size: 18px;
    margin-right: 10px;
    color: rgba(red, 0.5);
}

/*** Buttons Start ***/

.navbar-expand-md .navbar-nav .nav-item {
  .btn-secondary {
    position: relative;

    padding: 0.625rem 1.625rem 0.5rem;
  }
  .btn-secondary > span {
    position: relative;
    z-index: 2;

    transition: transform 300ms ease;
  }
  .btn-secondary:before {
    content: "";

    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border: 1px solid;
    background-color: $color-secondary;
    border-color: $color-secondary;

    transition: transform 300ms ease;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary,
  .btn-secondary:focus {
    background-color: transparent;
    border-color: transparent;
    color: white;
  }
  .btn-secondary:hover,
  .btn-secondary:active:focus {
    background-color: transparent;
    border-color: transparent;
    color: white;
  }
  .btn-secondary:hover:before,
  .btn-secondary:active:focus:before {
    transform: scale(1.075);

    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 2.275);
  }
  .btn-secondary:hover > span,
  .btn-secondary:active:focus > span {
    transform: scale(1.075);

    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 2.275);
  }
}

.btn {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;

  padding: 0.625rem 1.625rem 0.5rem;
  line-height: calc(2.875rem - 1.125rem - 2px);
  height: 2.875rem;

  border-radius: 0;

  transition-timing-function: ease;
  transition-property: background-color, color, border-color, transform;
  transition-duration: 300ms, 300ms, 300ms, 150ms;
  &-lg {
    line-height: calc(3.75rem - 1.125rem - 2px);
    height: 3.75rem;

    font-size: 1.25rem;

    min-width: 160px;
  }
  &:focus,
  &.focus {
    box-shadow: none;
  }
  &-secondary:not(:disabled):not(.disabled).active:focus,
  &-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &-primary:not(:disabled):not(.disabled).active:focus,
  &-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &-primary:not(:disabled):not(.disabled):active,
  &-primary,
  &-primary:focus {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
  }
  &-primary:hover,
  &-primary:active:focus {
    background-color: lighten($color-primary, 10%);
    border-color: lighten($color-primary, 10%);
    color: white;
  }
  &-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &-secondary:not(:disabled):not(.disabled):active,
  &-secondary,
  &-secondary:focus {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: white;
  }
  &-secondary:hover,
  &-secondary:active:focus {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: white;
  }
  &-outline-dark {
    min-width: 160px;
  }
  &-outline-dark:before {
    content: "";

    position: absolute;

    left: 0;
    top: 0;

    width: 100%;
    height: 50%;

    transform: scaleY(0);
    transform-origin: center bottom;

    background: $color-dark;

    z-index: -1;

    transition: transform 300ms ease;
  }
  &-outline-dark:after {
    content: "";

    position: absolute;

    left: 0;
    bottom: 0;

    width: 100%;
    height: 50%;

    transform: scaleY(0);
    transform-origin: center top;

    background: $color-dark;

    z-index: -1;

    transition: transform 300ms ease;
  }
  &-outline-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &-outline-dark:not(:disabled):not(.disabled):active,
  &-outline-dark,
  &-outline-dark:focus {
    background-color: transparent;
    border-color: $color-dark;
    color: $color-dark;
  }
  &-outline-dark:hover,
  &-outline-dark:active:focus {
    background-color: transparent;
    border-color: $color-dark;
    color: white;
  }
  &-outline-dark:hover:before,
  &-outline-dark:hover:after {
    transform: scaleY(1);
  }
  &-white-red:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &-white-red:not(:disabled):not(.disabled):active,
  &-white-red,
  &-white-red:focus {
    background-color: white;
    border-color: white;
    color: $color-primary;
  }
  &-white-red:hover,
  &-white-red:active:focus {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
  }
  &-white {
    border-radius: 6px;
  }
  &-white:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &-white:not(:disabled):not(.disabled):active,
  &-white,
  &-white:focus {
    background-color: white;
    border-color: white;
    color: $color-dark;
  }
  &-white:hover,
  &-white:active:focus {
    background-color: white;
    border-color: white;
    color: $color-dark;
  }
}

/*** Buttons End ***/

/*** Misc Start ***/

.section {
  padding: $section-gutter 0;
  > .d-flex {
    margin: -$section-gutter 0;
    padding: $section-gutter 0;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
}

.bg-white {
  background-color: white;
}

*:focus {
  outline: 0 !important;
}

/*** Misc End ***/

/*** Header Start ***/

header {
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;

  z-index: 2019;

  transition: opacity 300ms ease;
}

.header-scrolled {
  .navbar {
    background: $color-primary;
  }
}

.navbar-expand-md {
  background-color: transparent;

  border: 0;
  border-radius: 0;

  margin: 0;

  transition: background 300ms ease;
}

.navbar-nav {
  .nav-item > .nav-link {
    font-weight: 500;
  }
  .nav-item > .nav-link:hover {
    background-color: transparent;
  }
  .nav-item > .nav-link:focus {
    background-color: transparent;
  }
}

.navbar-invert {
  .navbar-nav {
    .nav-item > .nav-link {
      color: white;
    }
  }
}

/*** Header End ***/

/*** Hero Start ***/

.hero {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    position: relative;
    z-index: 99;

    width: 100%;

    padding: 4rem 0;
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
}

#hero-home {
  overflow: hidden;
  background-color: #F10000;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 99vh;
  min-height: 500px;

  color: white;
  .hero-background {
    background-position: right top;
    background-size: 1440px auto;
  }
}

.hero-background {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*** Hero End ***/

/*** Main Start ***/

.hero-content-text {
  h1 {
    line-height: 1;
  }
  h1 + p {
    margin-top: 4rem;
    max-width: 400px;
  }
}

.scroll-down {
  position: absolute;

  bottom: 0;
  left: 0;

  width: 100%;

  margin-left: -114px;

  pointer-events: none;
}

.guideline{
  position: absolute;

  left: 54px;
  top: 8px;
  width: 60px;
  height: 1px;

  background: rgba(255,255,255,0.5);
}

.scroll-down-symbol {
  line-height: 1;

  transform: rotate(90deg);
  transform-origin: right bottom;

  width: 114px;
  height: 16px;

  white-space: nowrap;

  &:after {
    content: "";
    position: absolute;
    display: inline-block;

    vertical-align: baseline;
    left: 54px;
    top: 8px;
    width: 60px;
    height: 1px;

    background: white;
    -webkit-animation: lineLoop 2s infinite;
    -moz-animation:    lineLoop 2s infinite;
    -o-animation:      lineLoop 2s infinite;
    animation:         lineLoop 2s infinite;
  }
}

@-webkit-keyframes lineLoop {
  0%   { width: 0; }
  50% { width: 60px; }
  100% { width: 0px; left: 130px;}
}
@-moz-keyframes lineLoop {
  0%   { width: 0; }
  50% { width: 60px; }
  100% { width: 0px; left: 130px;}
}
@-o-keyframes lineLoop {
  0%   { width: 0; }
  50% { width: 60px; }
  100% { width: 0px; left: 130px;}
}
@keyframes lineLoop {
  0%   { width: 0; }
  50% { width: 60px; }
  100% { width: 0px; left: 130px;}
}


@-webkit-keyframes boxLoop {
  0%   { background-color: #4D4F4F;  }
  50% { background-color: #3D63DA; }
  100% { background-color: #4D4F4F;}
}
@-moz-keyframes boxLoop {
  0%   { background-color: #4D4F4F;  }
  50% { background-color: #3D63DA; }
  100% { background-color: #4D4F4F;}
}
@-o-keyframes boxLoop {
  0%   { background-color: #4D4F4F;  }
  50% { background-color: #3D63DA; }
  100% { background-color: #4D4F4F;}
}
@keyframes boxLoop {
  0%   { background-color: #4D4F4F;  }
  50% { background-color: #3D63DA; }
  100% { background-color: #4D4F4F;}
}
.intro-heading {
  h2 {
    font-size: 2.625rem;
    font-weight: 600;
    letter-spacing: 0;
  }
  h2 > span:nth-child(1) {
    display: block;

    font-family: $font-stack-primary;
    font-size: 0.38em;
    font-weight: 900;
    letter-spacing: 0.15em;
    line-height: 1.88;
    text-transform: uppercase;
  }
  h2 > span:nth-child(2) {
    color: $color-primary;

    display: block;
  }
}

.intro-video-grid {
  display: flex;
  flex-flow: row wrap;
}

.intro-video-content {
  width: 340px;

  margin-right: 70px;
}

.intro-video-content-text {
  p {
    margin-bottom: 0;

    font-size: 1.125rem;
    line-height: 1.77;
  }
  p + * {
    margin-top: 3rem;
  }
}

.intro-video-media {
  flex-grow: 1;
}

.intro-video-media-video {
  position: relative;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border-radius: 6px;

  overflow: hidden;
  &:before {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
  &:after {
    content: "\f144";

    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    color: white;

    font-family: "Font Awesome 5 Pro";
    font-weight: 700;
    font-size: 3rem;

    transition: transform 200ms ease;
  }
  &:hover:after {
    cursor: pointer;

    transform: translate(-50%, -50%) scale(1.125);
  }
  &.video-playing:after {
    display: none;
  }
  .embed-responsive {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

.advantages-content {
  margin-bottom: 3rem;
}

.advantages-content-text {
  > span {
    display: block;

    font-size: 1.25rem;
    font-weight: 900;
    letter-spacing: 0.125em;
    text-transform: uppercase;

    color: $color-primary;

    margin-bottom: 2rem;
  }
  h3 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.0625;
  }
  h3 + * {
    margin-top: 3rem;
  }
}

.advantages-grid {
  flex: 1;

  min-width: 420px;
}

.advantages-grid-card {
  padding: 2.25rem;

  border: 1px solid #7A7A7A;
  border-radius: 6px;

  min-height: 240px;

  transition-property: background-color, color, border-color;
  transition-timing-function: ease;
  transition-duration: 300ms;
  h6 {
    font-family: $font-stack-primary;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.545;

    color: $color-primary;

    transition-property: color;
    transition-timing-function: ease;
    transition-duration: 300ms;
  }
  h6 + p {
    margin-top: 1.5rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.7;

    margin-bottom: 0;
  }
}

.grid-across {
  margin: 0 !important;
  .grid-item {
    padding: 0 !important;
  }
}

.option-card {
  position: relative;
  overflow: hidden;

  padding: 5.625rem 15px;
}

.option-card-bg {
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  transition: transform 300ms ease;
  &:before {
    content: "";

    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba($color-dark, 0.7);

    opacity: 0;

    transition: opacity 300ms ease;
  }
}

.option-card-content {
  position: relative;

  z-index: 12;
  margin-left: 60%;
  max-width: 460px;

  color: white;
  > span {
    font-size: 0.625rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;

    display: inline-block;

    margin-bottom: 0.75rem;
  }
  h4 {
    color: $color-primary;

    font-family: $font-stack-primary;
    font-size: 5.25rem;
    font-weight: 700;
    line-height: 0.95;
  }
  h4 + p {
    margin-top: 1rem;
  }
  p {
    margin-bottom: 0;

    font-size: 1.125rem;
    line-height: 1.77;
  }
  p + p {
    margin-top: 2rem;
  }
}

.pricing-header {
  h3 {
    color: #C4C4C4;

    font-size: 2.875rem;
    font-weight: 400;
    line-height: 1.06;
    letter-spacing: -0.5px;
  }
  h3 > span {
    color: $color-primary;
  }
}

.pricing-card {
  background: #1C1C1C;
  color: white;

  padding: 2rem;
}

.pricing-card.recommended {
  background: $color-primary;
  color: white;
  .category {
    color: white;
  }
  .btn-white-red:hover,
  .btn-white-red:active:focus {
    background: $color-dark;
    border-color: $color-dark;
    color: white;
  }
}

.pricing-card-header {
  .category {
    font-size: 0.9375rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.7333333333;
    letter-spacing: 2px;

    color: $color-primary;
  }
  .name {
    font-family: $font-stack-secondary;
    font-size: 2.375rem;
    font-weight: 600;
    line-height: 1.2631578947;
    letter-spacing: 0;
  }
}

.pricing-card-body {
  margin: 2rem 0;
}

.pricing-card-footer {
  .btn {
    font-size: 1.5625rem;
    font-weight: 900;

    line-height: calc(3.75rem - 1.125rem - 2px);
    height: 3.75rem;

    display: flex;
  }
  .btn > span {
    position: relative;
  }
  .btn.note > span:after {
    content: "*";

    position: absolute;

    top: 0;
    right: 0;

    transform: translateX(100%);

    margin-right: -0.25rem;
    margin-top: -0.25rem;

    color: #979EA6;
  }
}

.ul-features {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 0.9375rem;
    font-weight: 500;
  }
  li + li {
    margin-top: 1.75rem;
  }
}

.pricing-disclaimer {
  margin-top: 2rem;

  color: #979EA6;

  font-weight: 500;

  margin-bottom: 0;
}

.contact-card {
  background: $color-dark;
  color: white;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  min-height: 100%;

  padding: 4rem 1rem;
}

.contact-card-content {
  max-width: 460px;
  h3 {
    font-size: 2.875rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.77;

    margin-bottom: 1rem;
  }
  h3 + p {
    margin-top: 0.5rem;
  }
  .progress-button {
    margin-top: 2rem;
  }
  .contact {
    font-size: 15px;
    line-height: 28px;
  }
  .contact a {
    color: white;
  }
}

.google-map {
  position: relative;

  width: 100%;
  height: 100%;

  background: $color-dark;

  //min-height: 300px;
  &:before {
    content: "";
    display: block;
    padding-bottom: 75%;
  }
}

#googleMap {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.form-control {
  height: 3.75rem;

  font-size: 1.125rem;

  padding: 0.75rem 1rem;

  background: transparent;
  box-shadow: none;
  border-color: white;
  color: white;
  &:focus {
    box-shadow: none;
    border-color: white;
    background: transparent;
    color: white;
  }
  &::placeholder {
    color: white;
  }
  &:focus::placeholder {
    color: rgba(white, 0.5);
  }
}
.g-recaptcha{
  margin-top: 20px;
}
.process-card {
  padding: 3rem 1rem;

  background: white;
  box-shadow: 20px 20px 40px rgba($color-dark, 0.15);

  max-width: 440px;

  position: absolute;

  top: 0;
  left: 0;
  &:before {
    content: "";

    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    height: 5px;

    background: $color-primary;

    pointer-events: none;
  }
}

.process-card-content {
  h6 {
    font-family: $font-stack-primary;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.54;
    letter-spacing: 1px;

    margin-bottom: 1.5rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.77;

    margin-bottom: 0;
  }
}

.process-media {
  img {
    max-width: 100%;

    display: block;
  }
}

.process-media-right {
  img {
    margin-left: auto;
  }

}

.right-padding{
  padding-right: 2rem;
}

.swiper-advantages-wrapper {
  position: relative;

  width: 100%;
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin-top: 2rem;

    position: relative;
  }
  .swiper-pagination-bullet {
    margin: 0 3px;

    width: 60px;
    height: 3px;

    opacity: 1;

    border-radius: 0;

    background-color: #C4C4C4;

    transition: background-color 300ms ease;
  }
  .swiper-pagination-bullet-active {
    background-color: $color-primary;
  }
}

.swiper-advantages {
  position: relative;
  overflow: hidden;

  margin: 0 -15px;
  padding: 0 30px;
  .swiper-slide-active .advantages-grid-card {
    background: $color-primary;
    border-color: $color-primary;

    color: white;
    h6 {
      color: white;
    }
  }
}

.hero-video {
  position: absolute;

  bottom: 0;
  right: 0;

  width: 1300px;
  height: 100%;

  max-width: calc(100% - (510px / 2));

  pointer-events: none;
  video {
    position: absolute;

    bottom: 0;
    right: 0;

    width: auto;
    height: auto;

    max-height: 100%;
    max-width: 100%;
  }
}

.f-modal-is-open {
  overflow: hidden;
}

.f-modal {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-overflow-scrolling: touch;

  background-color: rgba(black, 0.75);

  opacity: 0;
  visibility: hidden;

  transition: all .4s ease;

  display: none;

  z-index: 2019;
  &.f-modal-open {
    opacity: 1;
    visibility: visible;
  }
  &-table {
    display: table;
    table-layout: fixed;

    width: 100%;
    height: 100%;
  }
  &-cell {
    display: table-cell;
    vertical-align: middle;
  }
  &-content {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
  }
  .f-modal-close {
    position: absolute;

    top: 12px;
    right: 12px;

    width: 1rem;
    height: 1rem;

    cursor: pointer;

    z-index: 101;
    &:hover {
      opacity: 0.5;
    }
    &:before,
    &:after {
      content: "";

      position: absolute;

      top: 0;
      left: 0.5rem;

      width: 2px;
      height: 100%;

      background-color: white;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}

.scroll-start {
  header,
  .navbar-item-active-dot {
    opacity: 0 !important;
  }
}

.intro-heading[data-animate="true"] {
  h2 > span {
    opacity: 0;
    transition: all 600ms ease;
  }
  h2 > span:nth-child(1) {
    transform: translate3d(-4rem, 0, 0);
  }
  h2 > span:nth-child(2) {
    transform: translate3d(4rem, 0, 0);
  }
}

.intro-heading[data-animate="true"].loaded {
  h2 > span {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.intro-video-content-text[data-animate="true"]{
  opacity: 0;
  transform: translate3d(0, 4rem, 0);
  transition: opacity 0.5s ease-out;
  transition: transform 600ms ease;
}
.intro-video-content-text[data-animate="true"].loaded {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.calendly {
  background: black;
}

.calendly-inline-widget {
  height: 992px;
}

.section-logos {
  overflow: hidden;
  padding: 20px 0 20px;
  background: #1f1f1f;
  color: white;
}

.logos {
  display: flex;

  .item img {
    display: block;
    width: auto;
    opacity: 0.5;
  }

  .marquee-track {
    display: inline-flex;
    animation: marquee-horizontal 10s linear infinite;
    will-change: transform;
  }

  .marquee-collection {
    --columnGap: 40px;

    display: inline-flex;
    align-items: center;
    column-gap: var(--columnGap);
    padding: 0 calc(var(--columnGap) / 2);
  }

  @keyframes marquee-horizontal {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
}

.section-work {
  overflow: hidden;
  padding: 60px 0 100px;
  background: #1f1f1f;
  color: white;
}

.pretitle {
  display: block;

  font-size: 1.25rem;
  font-weight: 900;
  letter-spacing: 0.125em;
  text-transform: uppercase;

  margin-bottom: 1rem;
}

.work-marquee {
  --columnGap: 16px;

  display: flex;
  margin: 60px 0 0;

  .marquee-track {
    display: inline-flex;
    animation: marquee-horizontal 60s linear infinite;
    will-change: transform;
  }

  .marquee-collection {
    display: inline-flex;
    align-items: center;
    column-gap: var(--columnGap);
    padding: 0 calc(var(--columnGap) / 2);
  }

  @keyframes marquee-horizontal {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
}

.work-card {
  position: relative;
  width: 338px;

  &:before {
    content: '';
    display: block;
    padding-bottom: calc(250 / 338 * 100%);
  }

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*** Main End ***/

#section-scroll {
  background: #1E1E1E;

  overflow: hidden;
}

.people-data-grid {
  padding: 0 15px;

  display: flex;
  flex-flow: row wrap;
}

.people-data-menu {
  display: none;

  align-self: center;

  counter-reset: process;
  .pricing-header {
    margin-bottom: 6rem;
    //margin-top: -6rem;
  }
}

.container-process {
  padding-left: 15px;
}

.people-data-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;

  border: 0;

  display: block;
  li {
    counter-increment: process;
  }
  li + li {
    margin-top: 2rem;
  }
  li a:hover {
    cursor: pointer;

    color: $color-primary;
  }
}

.people-data-title,
a.people-data-title {
  position: relative;

  color: #979797;

  //pointer-events: none;

  display: block;

  font-size: 2.875rem;
  font-weight: 900;
  line-height: 1.39;
  padding-left: 2.25rem;
  transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  &:before {
    content: counter(process);

    position: absolute;

    top: 0.35em;
    left: 0;

    font-size: 1.125rem;
    font-weight: 900;

    color: white;
  }
  &.active {
    color: $color-primary;
  }
  &:hover {
    cursor: pointer;

    color: $color-primary;
  }
}

.people-data-content {
  position: relative;
}

.people-data-content-holder {
  position: relative;
  > div {
    position: relative;

    transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.people-data-content-slide {
  position: relative;
  & + & {
    margin-top: 12rem;
  }
}

.people-data-content-slide:nth-child(1) {
  .process-card {
    top: 6rem;
  }
  .process-media + .process-media {
    margin-top: 4rem;
  }
}

.people-data-content-slide:nth-child(2) {
  .process-card {
    top: 13.625rem;
  }
  .process-media + .process-media {
    margin-top: 2rem;
  }
}

.people-data-content-slide:nth-child(3) {
  .process-card {
    top: 6rem;
  }
}

.people-data-content-slide:nth-child(4) {
  .process-card {
    top: 50%;
    transform: translateY(-50%);
  }
}

img[data-bg="true"] {
  display: none;
}

/*** Footer Start ***/

footer {
  padding: 4rem 0;

  background: white;
  h5 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 1.5;

    margin-bottom: 5rem;
  }
  h6 {
    font-family: $font-stack-primary;
    font-weight: 700;
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.81;

    margin-bottom: 1.5rem;
  }
}

.footer-contact {

  line-height: 1.75;
  a {
    color: $color-dark;
  }
  a:hover {
    color: $color-dark;
    text-decoration: underline;
  }
}

.powered-by {
  display: flex;
  align-items: center;

  margin-left: -40px;
  margin-top: -20px;
  img {
    margin-right: 1rem;
  }
  .text {
    font-size: 0.875rem;

    margin-top: -35px;
    margin-left: -30px;
  }
  .text > strong {
    font-size: 1.125rem;
    font-weight: 700;

    display: block;
  }
  .text > span {
    color: $color-primary;

    font-weight: 700;
  }
  .text > a {
    color: $color-primary;

    font-weight: 700;
  }
  .text > a:hover {
    text-decoration: underline;
  }
}

.footer-social {
  list-style: none;
  padding: 0;
  margin: 0 -0.3rem;

  display: flex;
  align-items: start;
  li {
    padding: 0 0.3rem;
  }
  li a {
    display: block;
  }
  li a img {
    //width: 18px;
  }
  li:first-child a img {
    //height: 18px;
  }
  li:last-child a {
    margin-left: -0.3rem;
  }
}

.copyright {
  margin-bottom: 0;

  font-size: 0.75rem;
  line-height: 1;
}

/*** Footer End ***/
