
@import 'custom';

@media all and (min-width: 576px) {

}

@media all and (min-width: 768px) {
  $header-padding: 123px;
  $section-gutter: 5.625rem;

  .navbar-expand-md {
    padding: 15px 0;
    min-height: 100px;
  }

  .navbar-nav {
    align-items: center;
    .nav-item {
      margin: 0 0 0 2rem;
    }
    .nav-item > .nav-link:not(.btn) {
      padding: 0;

      transition: color 300ms ease;
    }
    .nav-item > .nav-link:not(.btn):hover,
    .nav-item > .nav-link.active:not(.btn) {
      color: $color-dark;
    }
  }

  .navbar-item-active-dot {
    position: fixed;

    width: 5px;
    height: 5px;

    top: 73px;

    background: white;

    border-radius: 50%;

    z-index: 2020;

    margin-left: -2.5px;
    margin-top: 0.25rem;

    transition: transform 300ms ease, opacity 300ms ease 300ms;

    opacity: 0;
  }

  body {
    //padding-top: $header-padding;
  }

  .section {
    padding: $section-gutter 0;
    > .d-flex {
      margin: -$section-gutter 0;
      padding: $section-gutter 0;
    }
  }

  .hero {
    > .d-flex {
      padding-top: calc(#{$header-padding} + #{$section-gutter / 2});
      min-height: 920px;
    }
  }

  #hero-home {
    > .d-flex {
      padding-top: unset;
    }
  }

  .hero-content {
    max-width: 510px;
  }

  .hero-content-text {
    h1 {
      font-size: 5.25rem;
    }
    p {
      font-size: 1.125rem;
    }
  }

  .intro-heading {
    h2 {
      font-size: 4rem;
      font-weight: 600;
      letter-spacing: 0;
    }
    h2 > span:nth-child(1) {
      display: block;

      font-family: $font-stack-primary;
      font-size: 0.4em;
      font-weight: 900;
      letter-spacing: 0.15em;
      line-height: 1.88;
      text-transform: uppercase;
    }
    h2 > span:nth-child(2) {
      margin-left: 2.14em;
      margin-top: -0.14em;

      color: $color-primary;

      display: block;
    }
  }

  .advantages-content {
    width: 390px;

    margin-right: 140px;
    margin-bottom: 0;
  }

  .advantages-content-text {
    > span {
      display: block;

      font-size: 1.25rem;
      font-weight: 900;
      letter-spacing: 0.125em;
      text-transform: uppercase;

      color: $color-primary;

      margin-bottom: 2rem;
    }
    h3 {
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.0625;
    }
    h3 + * {
      margin-top: 4rem;
    }
  }

  .option-card {
    position: relative;
    overflow: hidden;

    padding: 140px;

    min-height: 100%;
    // &:hover &-bg {
    //   transform: scale(1.075);
    // }
    // &:hover &-bg:before {
    //   opacity: 1;
    // }
  }

  .pricing-header {
    h3 {
      font-size: 4.5rem;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.5px;
    }
  }

  .contact-card {
    padding: 5rem 2rem;
  }

  .advantages-grid-card {
    min-height: 100%;
    &:hover {
      background: $color-primary;
      border-color: $color-primary;

      color: white;
    }
    &:hover h6 {
      color: white;
    }
  }

  .logos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .item img {
      display: block;
      width: auto;
      opacity: 0.5;
    }

    .marquee-track {
      animation: none;
    }

    .marquee-collection {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 20px 60px;
    }

    .marquee-collection:nth-child(1) {
      display: none;
    }
  }
}

@media all and (min-width: 1024px) {
  .people-data-grid {
    padding: 0;

    margin-left: -30px;
    margin-right: -30px;

    height: 100%;
    > div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .people-data-grid .tab-pane-grid-item:nth-child(1) {
    max-width: 20rem;
  }

  .people-data-grid .tab-pane-grid-item:nth-child(2) {
    flex: 1;
  }

  .people-data-menu {
    width: 440px;

    display: flex;
    align-items: center;
  }

  .people-data-content {
    flex: 1;

    height: 100%;
  }

  .section-people-data-grid {
    display: flex;

    height: 100%;
  }

  .process-card{
    max-width: 375px;
  }

  .tab-pane-content-text,
  .people-data-content-text {
    h4 {
      font-size: 2rem;
      line-height: 1.225;
    }
  }
}

@media all and (min-width: 1280px) {
  .container-process {
    padding-left: calc((100vw - 1280px) / 2);
    flex: 1;
  }


  .process-card{
    max-width: 440px;
  }
}

@media all and (min-width: 1440px) {
  .container-process {
    padding-left: calc((100vw - 1440px) / 2 + 140px);

    flex: 1;
  }
}

@media all and (min-width: 1410px) and (max-height:830px){
  .hero-video {
   width: 1100px;
  }
}

@media all and (min-width: 1200px) and (max-height:728px){
  .hero-video {
   width: 1000px;
  }
}

@media all and (min-width: 1650px) {

}


@media all and (max-width: 1199px) {

}

@media all and (max-width: 1023px) {

  .process-media {
    display: none;
  }

  .process-card {
    position: relative;

    top: unset !important;
    transform: unset !important;
  }

  .people-data-content-slide {
    counter-increment: process;
  }

  .people-data-content-slide + .people-data-content-slide {
    margin-top: 2rem;
  }

  .container-process {
    padding: 0;
  }

  #section-scroll {
    padding: 5.625rem 0;
  }

  .people-data-content {
    width: 100%;
  }

  .process-card {
    max-width: none;
  }

  .people-data-title {
    pointer-events: auto;

    transition: color 300ms ease;
  }

  .process-card-frame {
    max-height: 0;
    overflow: hidden;

    transition: 300ms ease;
  }

  .process-card {
    &:before {
      content: "";

      position: absolute;

      top: 0;
      left: 0;
      right: 0;

      height: 5px;

      background: $color-primary;

      transform: scaleX(0);
      transform-origin: left center;
      transition: 300ms ease;

      pointer-events: none;
    }
  }

  .process-card-frame.active {
    .process-card:before {
      transform: scaleX(1);
    }
  }
}

@media all and (min-width: 1024px) and (max-height:742px) {
  .scroll-down{
    display: none;
  }
}

@media all and (max-width: 768px) {

}

@media all and (max-width: 767px) {
  h1 {
    font-size: 2.4em;
    line-height: 1.225;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  h6 {
    font-size: 1.2em;
  }

  .navbar-menu {
    position: fixed;

    top: 0;
    right: 0;

    width: 80%;
    height: 100vh;

    min-width: 290px;

    padding: 10rem 2rem 8rem;

    display: flex;
    flex-direction: column;

    transform: translate3d(100%, 0, 0);

    transition: transform 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

    background-color: $color-dark;

    z-index: 2019;
  }

  .navbar-menu-close {
    position: absolute;

    top: 26px;
    right: 15px;
  }

  .navbar-menu-open {
    transform: translate3d(0, 0, 0);
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;

    flex: 1;

    text-align: right;
    .nav-item {
      transform: translate3d(0, 100vh, 0);

      transition: transform 600ms ease;
    }
    @for $i from 1 through 5 {
      .nav-item:nth-child(#{$i}) {
        transition-delay: $i * 80ms;
      }
    }
    .nav-item > .nav-link {
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 0.66;

      display: block;

      color: white;

      position: relative;
    }
    .nav-item > .nav-link:after {
      content: "";

      position: absolute;

      transform: translateY(-50%);

      top: 50%;
      right: -15px;

      width: 5px;
      height: 5px;

      border-radius: 50%;

      background: transparent;
    }
    .nav-item > .nav-link.active:after {
      background: $color-primary;
    }
    .nav-item > .nav-link.active {
      color: $color-primary;
    }
    .nav-item + .nav-item {
      margin-top: 2rem;
    }
    .nav-item:last-child {
      margin-top: auto;
    }
  }

  .navbar-toggler {
    order: 1;
    margin-left: auto;
    border: 0;
    padding: 0;

    border-radius: 0;
  }

  .navbar-toggler,
  .navbar-brand {
    z-index: 1061;
  }

  .intro-video-media-video {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 1.5rem;
    margin-top: 1rem;

    border-radius: 0;
    &:before {
      padding-bottom: 100%;
    }
  }

  .intro-video-content-text p + * {
    margin-top: 1.5rem;
  }

  footer h5 {
    margin-bottom: 2rem;
  }

  #hero-home .hero-background {
    background-image: url('../img/hero-home-mobile.jpg') !important;
    background-size: 100% auto;
  }

  #hero-home > .d-flex {
    min-height: 1040px;

    padding-bottom: 10rem;
  }

  .hero-content {
    max-width: 360px;
  }

  .hero-content-text {
    h1 {
      font-size: 3.875rem;
      line-height: 1.22;
    }
    h1 + p {
      margin-top: 2rem;
    }
    p {
      font-size: 1.125rem;
      line-height: 1.55;
    }
  }

  .navbar {
    padding: 5px 0;
  }

  .navbar-brand {
    img {
      height: 56px;
    }
  }

  .option-card-content h4 {
    font-size: 4.5rem;
  }

  .advantages-grid {
    display: none;
  }

  .intro-video-media {
    min-width: 100%;
  }

  .calendly-inline-widget {
    height: 912px;
  }
}
/* iPad Pro Portrait */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    #hero-home {
      height: auto;
    }
}

@media all and (max-width: 575px) {
  #hero-home{
    height: auto;
  }

  .navbar-expand-md .navbar-nav .nav-item .btn-secondary{
    font-size: 1.25rem;
    padding: 1.25rem 1.525rem 1.75rem;
  }
  .right-padding{
    padding-right: 0;
  }
  .option-card-content {
    margin-left: 0;
  }
}

@media all and (max-width: 320px) {

}
