.progress-button {
  position: relative;
  display: inline-block;
  text-align: center;

  button {
    transition: all 0.3s;
  }
}

.progress-button svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.progress-button svg path, .progress-button svg circle {
  visibility: hidden;
  opacity: 0;
  fill: none;
}

.progress-button svg.checkmark path, .progress-button svg.cross path {
  stroke: #ffffff;
  stroke-width: 5;
  stroke-linecap: round;

  -webkit-transition: visibility 0s linear .1s, opacity 0.1s linear;
  transition: visibility 0s linear .1s, opacity 0.1s linear;
}

.loading.progress-button button {
  width: 70px;
  background-color: transprarent;
  border-width: 5px;
  border-color: #ddd;

  color: #fff;

  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.loading.progress-button button:hover, .loading.progress-button button:focus {
  background-color: transparent;
}

.loading.progress-button button span,
.success.progress-button button span,
.error.progress-button button span {
    visibility: hidden;
    opacity: 0;
}

.loading.progress-button svg.progress-circle circle,
.success.progress-button svg.checkmark path,
.error.progress-button svg.cross path {
    visibility: visible;
    opacity: 1;

    -webkit-transition: stroke-dashoffset 0.3s;
    transition: stroke-dashoffset 0.3s;
}

.success.progress-button button {
    background-color: #1ecd97;
    border-color: #1ecd97;
}
.error.progress-button button {
    background-color: #fb797e;
    border-color: #fb797e;
}

.confirmation-message {
  display: inline-block;
  width: calc(100% - 205px);
  margin-left: 20px;
  vertical-align: middle;
  opacity: 0;
}
