@for $i from 1 through 10 {
  .mt-#{$i} {
    margin-top: $i + rem !important;
  }
  .mb-#{$i} {
    margin-bottom: $i + rem !important;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.grid {
  display: flex;
  flex-flow: row wrap;

  margin: -15px;
  &-item {
    @include flex-grid(1);
    padding: 15px;
  }
}

@for $i from 1 through 5 {
  .grid-#{$i} .grid-item {
    @include flex-grid(#{$i});
  }
}

$grid-gutter: 15px;
$grid-gutter-2: 15px;

.container,
.container-fluid {
  padding-left: $grid-gutter-2;
  padding-right: $grid-gutter-2;
}

.hero .container,
.hero .container-fluid,
.navbar .container,
.navbar .container-fluid {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

@media all and (min-width: 576px) {
  @for $i from 1 through 10 {
    .mt-sm-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-sm-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-sm-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 15px;
  $grid-gutter-2: 15px;

  .container,
  .container-fluid {
    padding-left: $grid-gutter-2;
    padding-right: $grid-gutter-2;
  }

  .hero .container,
  .hero .container-fluid,
  .navbar .container,
  .navbar .container-fluid {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .section {
    padding: $section-gutter 0;
    > .d-flex {
      margin: -$section-gutter 0;
      padding: $section-gutter 0;
    }
  }
}

@media all and (min-width: 768px) {
  @for $i from 1 through 10 {
    .mt-md-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-md-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-md-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 30px;
  $grid-gutter-2: 30px;

  .container,
  .container-fluid {
    padding-left: $grid-gutter-2;
    padding-right: $grid-gutter-2;
  }

  .hero .container,
  .hero .container-fluid,
  .navbar .container,
  .navbar .container-fluid {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

@media all and (min-width: 1024px) {
  @for $i from 1 through 10 {
    .mt-lg-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-lg-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-lg-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 30px;
  $grid-gutter-2: 70px;

  .container,
  .container-fluid {
    padding-left: $grid-gutter-2;
    padding-right: $grid-gutter-2;
  }

  .hero .container,
  .hero .container-fluid,
  .navbar .container,
  .navbar .container-fluid {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

@media all and (min-width: 1280px) {
  @for $i from 1 through 10 {
    .mt-xl-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-xl-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-xl-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 50px;
  $grid-gutter-2: 140px;

  .container,
  .container-fluid {
    padding-left: $grid-gutter-2;
    padding-right: $grid-gutter-2;
  }

  .hero .container,
  .hero .container-fluid,
  .navbar .container,
  .navbar .container-fluid {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

@media all and (min-width: 1600px) {
  $grid-gutter: 50px;
  $grid-gutter-2: 140px;

  .container,
  .container-fluid {
    padding-left: $grid-gutter-2;
    padding-right: $grid-gutter-2;
  }

  .hero .container,
  .hero .container-fluid,
  .navbar .container,
  .navbar .container-fluid {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}