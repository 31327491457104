@font-face {
  font-family: 'Crimson Text';
  src: url('../fonts/CrimsonText-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Crimson Text';
  src: url('../fonts/CrimsonText-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Crimson Text';
  src: url('../fonts/CrimsonText-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson Text';
  src: url('../fonts/CrimsonText-Regular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson Text';
  src: url('../fonts/CrimsonText-Italic.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Crimson Text';
  src: url('../fonts/CrimsonText-Bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Heavy.woff') format('woff');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Book.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Roman.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}